<template>
  <v-container fluid>
    <v-row v-if="!editCard">
      <v-col>
        <div>
          <span>ID:</span>
          <span class="font-weight-medium ml-2">{{ employee.user.id }}</span>
        </div>
        <div>
          <span>Ім'я:</span>
          <span class="font-weight-medium ml-2">{{ employee.user.first_name }}</span>
        </div>
        <div>
          <span>Прізвище:</span>
          <span class="font-weight-medium ml-2">{{ employee.user.last_name }}</span>
        </div>
        <div>
          <span>Телефон:</span>
          <span class="font-weight-medium ml-2">{{ employee.user.phone }}</span>
        </div>
        <div>
          <span>Telegram:</span>
          <span class="font-weight-medium ml-2">{{ usersTelegram }}</span>
        </div>
        <div>
          <span>Viber:</span>
          <span class="font-weight-medium ml-2">{{ usersViber }}</span>
        </div>
        <div>
          <span>WhatsApp:</span>
          <span class="font-weight-medium ml-2">{{ usersWhatsApp }}</span>
        </div>
        <div>
          <span>Дата народження:</span>
          <span class="font-weight-medium ml-2">{{ employee.user.birthday | getShortDate }}</span>
        </div>

        <v-divider class="my-4"></v-divider>
        <RolesTable :employers="[employee]" :is-profile="false" />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <div>
          <span>ID:</span>
          <span class="font-weight-medium ml-2">{{ employee.user.id }}</span>
        </div>
        <div>
          <span>Ім'я:</span>
          <span class="font-weight-medium ml-2">{{ employee.user.first_name }}</span>
        </div>
        <div>
          <span>Прізвище:</span>
          <span class="font-weight-medium ml-2">{{ employee.user.last_name }}</span>
        </div>
        <div>
          <span>Телефон:</span>
          <span class="font-weight-medium ml-2">{{ employee.user.phone }}</span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Посада:</span>
          <v-select
            v-model.number="editedItem.position.id"
            :items="positions"
            item-text="name"
            item-value="id"
            class="ml-2"
            dense
            hide-details
          >
          </v-select>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Початок співпраці:</span>
          <v-menu
            v-model="menuStartCooperationDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="editedItem.start_cooperation"
                prepend-icon="mdi-calendar"
                hint="рiк-місяць-число"
                persistent-hint
                readonly
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              ></v-text-field>
            </template>
            <v-date-picker v-model="editedItem.start_cooperation" @input="menuStartCooperationDate = false"></v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Закінчення співпраці:</span>
          <v-menu
            v-model="menuStopCooperationDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="editedItem.stop_cooperation"
                prepend-icon="mdi-calendar"
                hint="рiк-місяць-число"
                clearable
                persistent-hint
                readonly
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              ></v-text-field>
            </template>
            <v-date-picker v-model="editedItem.stop_cooperation" @input="menuStopCooperationDate = false"></v-date-picker>
          </v-menu>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import convertDate from '@/mixins/convertDate'
import cloneDeep from '@/mixins/cloneDeep'
import { mapState } from 'vuex'
import RolesTable from '@/components/support/users/RolesTable.vue'

export default {
  name: 'StaffGeneralInfo',

  mixins: [convertDate, cloneDeep],

  components: { RolesTable },

  props: {
    employee: {
      type: Object,
      required: true,
      default: () => {},
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentTab: {
      required: true,
      default: null,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    editedItem: {},
    menuStartCooperationDate: false,
    menuStopCooperationDate: false,
  }),

  computed: {
    ...mapState('positions', ['positions']),

    usersTelegram() {
      return this.employee.user.contacts?.personal
        ? this.employee.user.contacts.personal[0]?.contact_type?.find((item) => item.type === 'telegram_channel')?.value || null
        : null
    },

    usersViber() {
      return this.employee.user.contacts?.personal
        ? this.employee.user.contacts.personal[0]?.contact_type?.find((item) => item.type === 'viber')?.value || null
        : null
    },

    usersWhatsApp() {
      return this.employee.user.contacts?.personal
        ? this.employee.user.contacts.personal[0]?.contact_type?.find((item) => item.type === 'whatsapp')?.value || null
        : null
    },
  },

  watch: {
    editCard(val) {
      if (!val) {
        this.initialize()
      }
    },
    isDataSaved(val) {
      if (val) {
        this.$emit('saveData', this.editedItem)
      }
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.editedItem = this.cloneObjectDeep(this.employee)
    },
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 500px !important;
  strong {
    min-width: 170px;
  }
}
</style>
