<template>
  <div>
    <span class="font-weight-medium ml-2">Ролі</span>
    <div v-for="(employee, index) in employers" :key="index" class="ml-2">
      <table class="mt-2">
        <tr>
          <td class="font-weight-medium">Компанія {{ employee.company.name }}</td>
          <td class="font-weight-medium">{{ getUserRoles(employee) }}</td>
        </tr>
        <tr>
          <td>Початок співпраці</td>
          <td>{{ employee.start_cooperation | getShortDate }}</td>
        </tr>
        <tr>
          <td>Закінчення співпраці</td>
          <td>{{ employee.stop_cooperation | getShortDate }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import convertDate from '@/mixins/convertDate'
import convertRolesTypes from '@/mixins/convertRolesTypes'

export default {
  name: 'RolesTable',

  mixins: [convertDate, convertRolesTypes],

  props: {
    employers: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getUserRoles(employee) {
      return employee?.position?.roles?.map((empItem) => this.getRoleTitle(empItem)).join(', ') || ''
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;

  th,
  td {
    min-width: 310px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 10px;
  }
}
</style>
