import { ROLES } from '@/const/roles.enum'

export default {
  methods: {
    getRoleTitle(val) {
      const currentRole = ROLES.filter((role) => role.type === val)
      if (currentRole.length) {
        return currentRole[0].title
      } else return val
    },
  },
}
